import { ArrowDropUp, ArrowRight } from '@mui/icons-material';
import React from 'react';

export default function Footer() {
    return (
        <>
            <footer className='flex lg:gap-10 flex-col lg:flex-row lg:px-20 px-5 bg-black py-4 text-[#f1f1f1] font-[Lato] space-y-6 lg:space-y-0 lg:space-x-6'>
                <div className='flex-1'>
                    <span className='text-lg font-semibold'>About Us</span>
                    <article className='mt-2 text-sm leading-relaxed'>
                        TGCI is dedicated to fostering technological proficiency and innovation through comprehensive computer education. With a focus on practical learning and real-world applications, we prepare individuals for successful careers in technology and beyond. Join us in shaping the future with cutting-edge education and professional development opportunities.
                    </article>
                    <a href='#' className='mt-2 inline-flex items-center text-sky-700'>
                        Read More <ArrowRight />
                    </a>
                </div>

                <div className='flex-[0.6]'>
                    <span className='text-lg font-semibold'>Contact Us</span>
                    <p className='mt-2 text-sm'>Tel: 7533839843</p>
                    <p className='mt-1 text-sm'>Mail: tgci.ac.in@gmail.com</p>
                    <p className='mt-1 text-sm'>
                        12 Nadi Rispana<br />
                        Near D.A.V college, Dehradun
                    </p>
                </div>

                <div className='flex-[0.6]'>
                    <span className='text-lg font-semibold'>Quick Links</span>
                    <p className='mt-2 text-sm'>Courses</p>
                    <p className='mt-1 text-sm'>About Us</p>
                    <a href='#' className='mt-1 inline-flex items-center text-sm'>
                        Back To Top <ArrowDropUp />
                    </a>
                </div>
            </footer>
        </>
    );
}
