import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import NotFound from './pages/NotFound';
import Loader from './components/Loader';

export default function App() {
  const Home = lazy(() => import('./pages/Home'));
  const About = lazy(() => import('./pages/About'));
  const Contact = lazy(() => import('./pages/Contact'));
  const Courses = lazy(() => import('./pages/Courses'));
  const pageVariants = {
    initial: { opacity: 0, y: 0 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -50 },
  };

  const pageTransition = {
    duration: 0.3,
    ease: "easeInOut",
  };

  return (
    <>
      <BrowserRouter>
        <Suspense fallback={
                  <div className='fixed h-[100vh] w-[100vw] top-0 left-0 bg-white flex items-center justify-center z-50' >
                    <Loader />
                  </div>}>
          <Header />
          <Routes >
            <Route
              path='/'
              element={
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}
                >
                  <Home />

                </motion.div>
              }
            />
            <Route
              path='/about'
              element={
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}
                >
                  <About />
                </motion.div>
              }
            />
            <Route
              path='/contact'
              element={
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}
                >
                  <Contact />
                </motion.div>
              }
            />
            <Route
              path='/courses'
              element={
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}
                >
                  <Courses />
                </motion.div>
              }
            />
            <Route
              path='*'
              element={
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}
                >
                  <NotFound />
                </motion.div>
              }
            />
          </Routes>

          <Footer />
        </Suspense>
      </BrowserRouter>
    </>
  );
}
