import React, { useState } from 'react'
import logo from '../assets/tgci.png'
import styled from 'styled-components'
import { IconButton, Modal, Box, Typography, TextField, Button } from '@mui/material';
import { ArrowRight, Call, LocationOn, Mail, Opacity } from '@mui/icons-material';
import './Header.css'
import { Hamburger } from '../utils/Hamburger';
import { motion } from 'framer-motion'
const Link = styled.a`
  font-family: Lato;
  text-transform: uppercase;
  font-weight: 550;
  cursor: pointer;
  font-size: 14px;
`;

const modalStyle = {
    position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // width: "97vw",
    // height: "97vh",
    border: "none",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function Header() {
    const [showNav, setShowNav] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    return (
        <div className='relative z-50 lg:h-[164px] h-[102px]'>
            <div className='fixed w-full'>
                <nav className='hidden w-full text-sm bg-black justify-between lg:px-20 lg:flex px-3 text-white py-1 items-center'>
                    <div className="flex gap-2">
                        <div className="inherit flex gap-1">
                            <Mail style={{ fontSize: "20px" }} />
                            tgci.ac.in@gmail.com
                        </div>
                        <span className='flex gap-1'>
                            <Call style={{ fontSize: "20px" }} />
                            Contact Us : 7533839843
                        </span>
                    </div>
                    <div>
                        <span className='flex gap-2'>
                            <LocationOn style={{ fontSize: "20px" }} />
                            <span>Visit Us <ArrowRight /></span>
                        </span>
                    </div>
                </nav>
                <nav className='bg-white w-full flex lg:shadow-xl shadow-md justify-between lg:px-20 px-5 py-4'>
                    <div className="logo flex gap-4 items-center">
                        <img src={logo} className='lg:h-[100px] lg:w-[100px] w-[70px] h-[70px]' alt="TGCI Logo" />
                        <div>
                            <h2 className='text-xl font-bold font-[Raleway]'>TGCI</h2>
                            <span>We Promote Learning</span>
                        </div>
                    </div>
                    <div className='lg:flex hidden gap-5 items-center'>
                        <Link href="/" className='text-[#666] hover:text-[#333]'>Home</Link>
                        <Link href="/about" className='text-[#666] hover:text-[#333]'>About</Link>
                        <Link href="/courses" className='text-[#666] hover:text-[#333]'>Courses</Link>
                        <Link href="/services" className='text-[#666] hover:text-[#333]'>Services</Link>
                        <Link href="/contact" className='text-[#666] hover:text-[#333]'>Contact</Link>
                        {/* Register Now Button */}
                        <button
                            onClick={handleOpen}
                            className="cursor-pointer bg-gradient-to-r font-bold font-[Hind] from-orange-300 text-[#fff] to-orange-500 py-2 px-4 rounded-[18px] "
                        >

                            Register Now
                        </button>
                    </div>
                    <div className='lg:hidden flex items-center'>
                        <Hamburger showNav={showNav} setShowNav={setShowNav}></Hamburger>
                    </div>
                    <div style={{ height: "auto" }} className={`lg:hidden absolute z-[50] top-[102px] overflow-hidden bg-white w-full left-0 ${showNav ? 'showMenu' : 'hideMenu'}`}>
                        <div className={`flex flex-col items-center list-none gap-7 py-9`}>
                            <Link href="/" className='text-[#666] hover:text-[#333]'>Home</Link>
                            <Link href="/about" className='text-[#666] hover:text-[#333]'>About</Link>
                            <Link href="/courses" className='text-[#666] hover:text-[#333]'>Courses</Link>
                            <Link href="/services" className='text-[#666] hover:text-[#333]'>Services</Link>
                            <Link href="/contact" className='text-[#666] hover:text-[#333]'>Contact</Link>
                        </div>
                    </div>
                </nav>
            </div>

            {/* Modal for Registration Form */}
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}

                >
                    <Box sx={modalStyle}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            Register Now
                        </Typography>
                        <Typography id="modal-description" sx={{ mt: 2 }}>
                            Fill in the details below to register.
                        </Typography>
                        <form>
                            <TextField
                                fullWidth
                                label="Name"
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                fullWidth
                                label="Email"
                                margin="normal"
                                variant="outlined"
                                type="email"
                            />
                            <TextField
                                fullWidth
                                label="Phone Number"
                                margin="normal"
                                variant="outlined"
                                type="tel"
                            />
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                sx={{ mt: 2 }}
                                onClick={handleClose} // Replace with registration logic
                            >
                                Submit
                            </Button>
                        </form>
                    </Box>
                </motion.div>
            </Modal>
        </div>
    )
}
